@import '../../../../scss/theme-bootstrap';

.hp_hero_block {
  position: relative;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  width: 100%;
  &__tabs {
    &.carousel-content {
      position: absolute;
      #{$ldirection}: 0;
      bottom: 10px;
      width: 100%;
      @include breakpoint($medium-up) {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      @include breakpoint($landscape-up) {
        top: 0;
        display: flex;
        flex-direction: column;
        z-index: 1;
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 0;
          width: 2px;
          height: 100%;
          background-color: $color-white;
          opacity: 0.4;
          z-index: 1;
          transition: all 0.666s cubic-bezier(0.65, 0.05, 0.36, 1);
        }
        &::before {
          #{$ldirection}: 50%;
        }
        &::after {
          #{$ldirection}: 74%;
        }
      }
      &[data-slide-tab-active='first'] {
        @include breakpoint($landscape-up) {
          &::before {
            #{$ldirection}: 50%;
          }
          &::after {
            #{$ldirection}: 74%;
          }
        }
      }
      &[data-slide-tab-active='default'] {
        @include breakpoint($landscape-up) {
          &::before {
            #{$ldirection}: 26%;
          }
          &::after {
            #{$ldirection}: 74%;
          }
        }
      }
      &[data-slide-tab-active='last'] {
        @include breakpoint($landscape-up) {
          &::before {
            #{$ldirection}: 26%;
          }
          &::after {
            #{$ldirection}: 50%;
          }
        }
      }
      .content-block {
        &__line,
        &__link {
          cursor: pointer;
          display: none;
          white-space: nowrap;
          &--eyebrow {
            display: block;
          }
        }
      }
      .slick-active {
        .content-block {
          &__line,
          &__link {
            display: block;
          }
        }
      }
      .slick-slide {
        @include breakpoint($landscape-up) {
          width: 24%;
        }
        &.slick-active {
          @include breakpoint($landscape-up) {
            width: 48%;
          }
        }
        &:first-child,
        &:last-child {
          @include breakpoint($landscape-up) {
            width: 26%;
          }
          &.slick-active {
            @include breakpoint($landscape-up) {
              width: 50%;
            }
          }
        }
      }
      .carousel-dots {
        display: none;
      }
    }
  }
}
